<template>
  <div class="container-fluid" id="order-container">
    <div class="row">
      <div class="col-lg-12">
        <div
          class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-2"
        >
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="font-weight-bold">{{ titleText }}</h4>
          </div>
          <div class="create-workform">
            <div
              class="d-flex flex-wrap align-items-center justify-content-between"
            >
              <div class="modal-product-search display-search d-flex">
                <div class="mr-3 position-relative search-input">
                  <search
                    :search="search"
                    :placeholder="'Search'"
                    v-model="search"
                    @searchFilter="handleSearchOrder"
                  >
                  </search>
                  <p class="search-css">Search by number, car vin, car license plate</p>
                </div>
              </div>
              <div id="add-order">
              <router-link
                  :to="{ name: 'order-add' }"
                  class="
                    btn btn-success btn-sm
                    position-relative
                    d-flex
                    align-items-center
                    
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2"
                    width="20"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                  <div class="white-space">Add Order</div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-block card-stretch">
              <div class="card-body-2">
                <div
                  class="mb-2 d-flex flex-wrap align-items-center justify-content-between"
                >
                  <h5>
                    Order List

                    <button
                      class="ml-2 btn btn-success btn-sm"
                      @click="refreshClick()"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="white"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M20.944 12.979c-.489 4.509-4.306 8.021-8.944 8.021-2.698 0-5.112-1.194-6.763-3.075l1.245-1.633c1.283 1.645 3.276 2.708 5.518 2.708 3.526 0 6.444-2.624 6.923-6.021h-2.923l4-5.25 4 5.25h-3.056zm-15.864-1.979c.487-3.387 3.4-6 6.92-6 2.237 0 4.228 1.059 5.51 2.698l1.244-1.632c-1.65-1.876-4.061-3.066-6.754-3.066-4.632 0-8.443 3.501-8.941 8h-3.059l4 5.25 4-5.25h-2.92z"
                        />
                      </svg>
                      Refresh
                    </button>
                  </h5>

                  <div class="d-flex flex-wrap mt-2 mobile-align-customer">
                    <div class="sorting-div">
                      <sortingText
                        :sortingData="sortingData"
                        :sorting="sorting"
                        @changeSorting="onChangeSorting"
                      ></sortingText>
                    </div>

                    <div class="d-flex filters-div">
                      <div class="ml-2 filter-div">
                        <button
                          class="btn btn-primary btn-sm d-flex align-items-center"
                          @click="filterClick()"
                          v-on:click="showFilters = !showFilters"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="mr-1"
                            width="15"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                          </svg>
                          <div class="notify">
                            filters
                            <div class="badge" v-if="countFilter">
                              <div class="message-count">{{ countFilter }}</div>
                            </div>
                          </div>
                        </button>
                      </div>

                      <div class="ml-2">
                        <button
                          class="btn btn-warning btn-sm d-flex align-items-center reset-btn-padding-onlist"
                          @click="resetFilters"
                          title="Reset Filters"
                        >
                          <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 1280.000000 1266.000000"
                            preserveAspectRatio="xMidYMid meet"
                          >
                            <g
                              transform="translate(0.000000,1266.000000) scale(0.100000,-0.100000)"
                              fill="white"
                              stroke="none"
                            >
                              <path
                                d="M6130 12653 c-1136 -47 -2253 -397 -3200 -1002 -1163 -744 -2048
                            -1830 -2525 -3096 -433 -1150 -520 -2406 -249 -3595 169 -740 463 -1430 880
                            -2063 765 -1161 1874 -2034 3189 -2509 977 -354 2044 -467 3080 -328 1388 186
                            2694 829 3675 1810 129 129 1201 1318 1207 1339 2 8 -1951 1746 -1964 1746 -5
                            0 -200 -214 -433 -475 -596 -668 -655 -732 -782 -847 -599 -539 -1284 -857
                            -2083 -964 -292 -39 -726 -36 -1025 7 -1069 153 -1996 719 -2605 1589 -746
                            1066 -863 2457 -305 3632 359 755 979 1375 1745 1744 387 186 728 287 1180
                            351 213 29 712 33 927 5 649 -82 1206 -288 1723 -638 269 -182 481 -372 781
                            -704 153 -168 162 -181 146 -196 -9 -9 -287 -254 -617 -544 -330 -291 -601
                            -532 -603 -537 -2 -4 4 -8 13 -8 8 0 305 -68 658 -150 353 -83 851 -200 1107
                            -260 256 -60 726 -170 1045 -245 319 -75 831 -195 1138 -267 307 -71 560 -128
                            562 -126 3 2 -47 960 -110 2129 -63 1168 -119 2221 -126 2339 -6 118 -14 245
                            -17 282 l-7 67 -520 -459 c-286 -252 -522 -459 -525 -459 -3 -1 -66 67 -140
                            150 -194 219 -425 458 -575 595 -1250 1147 -2934 1759 -4645 1687z"
                              />
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <transition name="slide-fade">
                  <div class="row d-flex pb-5 pr-5 pl-5" v-if="showFilters">
                    <div class="col-md-2">
                      <label
                        for="car-make"
                        class="form-label text-uppercase text-muted filter-label-heading mt-3"
                        >From Date (Scheduled At)</label
                      >

                      <b-input-group
                        :class="{
                          borderColorFilter: gte !== null ? true : false,
                        }"
                      >
                        <b-form-datepicker
                          id="datepicker-from-date"
                          v-model="gte"
                          size="sm"
                          :class="{ singleOptions: true }"
                          calendar-width="100%"
                          placeholder="Select"
                          @context="onContext"
                          @input="onChangeFromDate($event)"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                        ></b-form-datepicker>
                        <template v-slot:append>
                          <button
                            class="filter-cross-btn"
                            @click="removeFromDate()"
                          >
                            x
                          </button>
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-md-2">
                      <label
                        for="car-make"
                        class="form-label text-muted text-uppercase filter-label-heading mt-3"
                        >To Date (Scheduled At)</label
                      >
                      <b-input-group
                        :class="{
                          borderColorFilter: lte !== null ? true : false,
                        }"
                      >
                        <b-form-datepicker
                          id="datepicker-to-date"
                          v-model="lte"
                          size="sm"
                          :class="{ singleOptions: true }"
                          calendar-width="100%"
                          placeholder="Select"
                          @input="onChangeToDate($event)"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                        >
                        </b-form-datepicker>
                        <template v-slot:append>
                          <button
                            class="filter-cross-btn"
                            @click="removeToDate()"
                          >
                            x
                          </button>
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-md-2">
                      <label
                        for="car-make"
                        class="form-label text-uppercase text-muted filter-label-heading mt-3"
                        >From Date (Quoted At)</label
                      >

                      <b-input-group
                        :class="{
                          borderColorFilter: gte_quoted !== null ? true : false,
                        }"
                      >
                        <b-form-datepicker
                          id="datepicker-from-date-quoted"
                          v-model="gte_quoted"
                          size="sm"
                          :class="{ singleOptions: true }"
                          calendar-width="100%"
                          placeholder="Select"
                          @context="onContext"
                          @input="onChangeQuotedAtFromDate($event)"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                        ></b-form-datepicker>
                        <template v-slot:append>
                          <button
                            class="filter-cross-btn"
                            @click="removeQuotedAtFromDate()"
                          >
                            x
                          </button>
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-md-2">
                      <label
                        for="car-make"
                        class="form-label text-muted text-uppercase filter-label-heading mt-3"
                        >To Date (Quoted At)</label
                      >
                      <b-input-group
                        :class="{
                          borderColorFilter: lte_quoted !== null ? true : false,
                        }"
                      >
                        <b-form-datepicker
                          id="datepicker-to-date-quoted"
                          v-model="lte_quoted"
                          size="sm"
                          :class="{ singleOptions: true }"
                          calendar-width="100%"
                          placeholder="Select"
                          @input="onChangeQuotedAtToDate($event)"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                        >
                        </b-form-datepicker>
                        <template v-slot:append>
                          <button
                            class="filter-cross-btn"
                            @click="removeQuotedAtToDate()"
                          >
                            x
                          </button>
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-md-2">
                      <label
                        for="car-make"
                        class="form-label text-muted text-uppercase filter-label-heading mt-3"
                        >Status</label
                      >
                      <multiselect
                        v-model="statusData"
                        :options="statusArray"
                        placeholder="Select"
                        :max="statusArray.length"
                        @select="onChangeStatus"
                        :show-labels="false"
                        :allow-empty="false"
                        id="orderStatus"
                        :class="{
                          borderColorFilter: status !== null ? true : false,
                          singleOptions: true,
                        }"
                      >
                      </multiselect>
                    </div>
                    <div class="col-md-2">
                      <label
                        for="car-make"
                        class="form-label filter-label-heading text-muted text-uppercase mt-3"
                        >Quoted
                      </label>

                      <multiselect
                        v-model="checkedIsQuoted"
                        :options="quotedArray"
                        placeholder="Select"
                        :max="quotedArray.length"
                        @select="onChangeIsQuoted"
                        @remove="removeIsQuoted($event)"
                        :show-labels="false"
                        :allow-empty="false"
                        :class="{
                          borderColorFilter: isQuoted !== null ? true : false,
                          singleOptions: true,
                        }"
                        :disabled="filterIsQuoted !== null"
                      >
                      </multiselect>
                    </div>
                    <div class="col-md-2">
                      <label
                        for="car-make"
                        class="form-label filter-label-heading text-muted text-uppercase mt-3"
                        >Quote Required
                      </label>

                      <multiselect
                        v-model="checkedQuoteRequired"
                        :options="quotedArray"
                        placeholder="Select"
                        :max="quotedArray.length"
                        @select="onChangeIsQuoteRequired"
                        @remove="removeIsQuoteRequired($event)"
                        :show-labels="false"
                        :allow-empty="false"
                        :class="{
                          borderColorFilter:
                            isQuoteRequired !== null ? true : false,
                          singleOptions: true,
                        }"
                      >
                      </multiselect>
                    </div>

                    <div class="col-md-2 filter-tabs">
                      <label
                        for="car-make"
                        class="form-label text-muted filter-label-heading text-uppercase mt-3"
                        >Workshop</label
                      >

                      <multiselect
                        v-model="workshop"
                        :options="workshopsArray"
                        :custom-label="getCustomWorkshop"
                        placeholder="Select Workshop"
                        :max="workshopsArray.length"
                        @select="onChangeWorkshop"
                        @open="handleWorkshop"
                        @remove="removeWorkshop"
                        :class="{
                          borderColorFilter: workshop !== null ? true : false,
                          singleOptions: true,
                        }"
                        :show-labels="false"
                        :allow-empty="true"
                        :searchable="true"
                        :internal-search="false"
                        :clear-on-select="false"
                        :close-on-select="true"
                        @search-change="handleSearchWorkshop"
                      >
                        <template slot="clear" slot-scope="props">
                          <span class="custom__tag assigned-to-custom">
                            <!-- <span>{{ option.firstName+ ' '+option.lastName }}</span> -->
                            <span
                              class="custom__remove"
                              v-if="workshop !== null"
                              @click="removeWorkshop(props)"
                              >x</span
                            >
                          </span>
                        </template>
                      </multiselect>
                    </div>
                    <div class="col-md-2">
                      <label
                        for="car-make"
                        class="form-label filter-label-heading text-muted text-uppercase mt-3"
                        >Is Fully Quoted
                      </label>

                      <multiselect
                        v-model="checkedIsFullyQuoted"
                        :options="quotedArray"
                        placeholder="Select"
                        :max="quotedArray.length"
                        @select="onChangeIsFullyQuoted"
                        @remove="removeIsFullyQuoted($event)"
                        :show-labels="false"
                        :allow-empty="false"
                        :class="{
                          borderColorFilter: isFullyQuoted !== null ? true : false,
                          singleOptions: true,
                        }"
                        :disabled="filterIsFullyQuoted !== null || filterIsQuoted !== null"
                      >
                      </multiselect>
                    </div>
                    <div class="col-md-2">
                      <label
                        for="car-make"
                        class="form-label filter-label-heading text-muted text-uppercase mt-3"
                        >Has Approved Quotation
                      </label>

                      <multiselect
                        v-model="hasApprovedQuotationData"
                        :options="quotedArray"
                        :placeholder="filterHasApprovedQuotation ? 'True' : 'Select'"
                        :max="quotedArray.length"
                        @select="onChangehasApprovedQuotation"
                        @remove="removehasApprovedQuotation($event)"
                        :show-labels="false"
                        :allow-empty="false"
                        :class="{
                          borderColorFilter:
                            hasApprovedQuotation !== null || filterHasApprovedQuotation ? true : false,
                          singleOptions: true,
                        }"
                        :disabled="filterHasApprovedQuotation"
                      >
                      </multiselect>
                    </div>
                    <div class="col-md-2">
                      <label
                        for="is-assigned-to-me"
                        class="form-label filter-label-heading text-muted text-uppercase mt-3"
                        >Is Assigned To Me
                      </label>
                      <b-form-checkbox
                        id="is-active"
                        size="lg"
                        v-model="checkedIsAssignedToMe"
                        @input="handleIsAssignedToMe"
                        name="check-button"
                        switch
                        :disabled="filterIsAssignedToMe !== null"
                      >
                      </b-form-checkbox>
                    </div>
                  </div>
                </transition>
                <div id="load-spinner">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <div class="table-responsive table-zoom" id="order-list">
                  <table class="table data-table table-order mb-0">
                    <thead class="table-color-heading">
                      <tr class="text-light">
                        <th scope="col">
                          <label class="text-muted m-0" for="text1">View</label>
                        </th>
                        <th scope="col">
                          <label class="text-muted m-0" for="text1"
                            >Number</label
                          >
                        </th>
                        <th scope="col" class="dates">
                          <label
                            class="text-muted mb-0"
                            for="validationServer01"
                            >Car</label
                          >
                        </th>
                        <th scope="col" class="dates">
                          <label
                            class="text-muted mb-0"
                            for="validationServer01"
                            >Status</label
                          >
                        </th>
                        <th scope="col">
                          <label
                            class="text-muted mb-0"
                            for="validationServer02"
                            >Schedule At</label
                          >
                        </th>
                        <th scope="col">
                          <label
                            class="text-muted mb-0"
                            for="validationServer02"
                            >Elapsed Days</label
                          >
                        </th>
                        <th scope="col">
                          <label
                            class="text-muted mb-0"
                            for="validationServer02"
                            >Quoted At</label
                          >
                        </th>
                        <th scope="col">
                          <label
                            class="text-muted mb-0"
                            for="validationServer02"
                            >Quoted</label
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-bind:class="
                          order.node.isQuoteRequired &&
                          isLessThan48HourAgo(
                            new Date(order.node.scheduledAt)
                          ) === true &&
                          order.node.workshopQuotedAt == null
                            ? 'red-highlight white-space-no-wrap'
                            : order.node.approvedQuotation
                            ? 'green-highlight white-space-no-wrap'
                            : 'white-space-no-wrap transition-row'
                        "
                        v-for="(order, index) in orderList"
                        :key="index"
                      >
                        <td>
                          <div
                            class="d-flex justify-content-around align-items-center"
                          >
                            <router-link
                              :to="'order/' + order.node.number"
                              class="click-area-extend"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="text-secondary"
                                width="20"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                />
                              </svg>
                            </router-link>
                          </div>
                        </td>
                        <td class="table-font">
                          <div title="Click To Copy">
                            <div
                              @click.prevent="
                                copyIdToClipboard(order.node.number)
                              "
                              class="customer-phone"
                            >
                              {{ order.node.number }}
                            </div>
                          </div>
                        </td>

                        <td class="table-font">
                          <div>
                            <div>
                              {{
                                order.node.car.model.make.name +
                                " " +
                                order.node.car.model.name
                              }}
                            </div>
                            <div v-if="order.node.car.licensePlate">
                              {{ order.node.car.licensePlate }}
                            </div>
                          </div>
                        </td>

                        <!-- STATUS -->
                        <td
                          v-if="order.node.status == 'SCHEDULED'"
                          class="mb-0 text-warning table-font font-weight-bold"
                        >
                          {{ order.node.status }}
                        </td>
                        <td
                          v-if="order.node.status == 'CANCELLED'"
                          class="mb-0 text-danger table-font font-weight-bold"
                        >
                          {{ order.node.status }}
                        </td>
                        <td
                          v-if="order.node.status == 'FULFILLED'"
                          class="mb-0 text-success table-font font-weight-bold"
                        >
                          {{ order.node.status }}
                        </td>
                        <td class="table-font">
                          <div :title="order.node.scheduledAt">
                              {{ order.node.scheduledAt | DateTimeFormat }}
                          </div>
                        </td>

                        <td class="table-font">
                          <div class="data-content">
                            {{
                              new Date(order.node.scheduledAt).getTime() >
                              new Date().getTime()
                                ? 0
                                : Math.ceil(
                                    (new Date().getTime() -
                                      new Date(
                                        order.node.scheduledAt
                                      ).getTime()) /
                                      (1000 * 3600 * 24) -
                                      1
                                  )
                            }}
                          </div>
                        </td>
                        <td class="table-font">
                          <div v-if="order.node.workshopQuotedAt">
                            <div>
                              {{ order.node.workshopQuotedAt | dateDayFormat }}
                            </div>

                            <div>
                              {{ order.node.workshopQuotedAt | timeFormat }}
                            </div>
                          </div>
                          <div v-else>-</div>
                        </td>
                        <td
                          v-if="order.node.workshopQuotedAt"
                          style="text-align: center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="green"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z"
                            />
                          </svg>
                        </td>
                        <td
                          class="table-font"
                          style="text-align: center"
                          v-else
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="red"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"
                            />
                          </svg>
                        </td>
                      </tr>
                      <tr v-if="totalRows === 0">
                        <td colspan="9" align="center">No data found</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row p-3" id="page-bottom">
                <div class="col-sm-12 col-md-6 d-flex align-items-center">
                  <div>
                    Total Orders: <b>{{ totalRows }}</b>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 d-flex justify-content-end">
                  <pagination
                    :currentPage="currentPage"
                    :perPage="perPage"
                    :totalRows="totalRows"
                    @changePage="handlePageChange"
                  ></pagination>
                </div>
              </div>
              <div class="row p-3">
                <!-- <div class="col-md-12">
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                      width="35"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M12 9.5C13.3807 9.5 14.5 10.6193 14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5Z"
                        fill="red"
                      ></path>
                    </g>
                  </svg>
                  -
                  <span style="font-weight: 600">
                    Accept/Reject the order
                  </span>
                </div> -->
                <div class="col-md-12">
                  <svg
                    width="18"
                    height="18"
                    fill="#ff00003b"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    stroke-linejoin="round"
                    stroke-miterlimit="2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1z"
                      fill-rule="nonzero"
                    />
                  </svg>
                  -
                  <span style="font-weight: 600">
                    Quote is required but not uploaded for more than 48hrs
                  </span>
                </div>
                <div class="col-md-12">
                  <svg
                    width="18"
                    height="18"
                    fill="#4cd74c8f"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    stroke-linejoin="round"
                    stroke-miterlimit="2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1z"
                      fill-rule="nonzero"
                    />
                  </svg>
                  -
                  <span style="font-weight: 600"> Approved Quotation </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_ORDERS_LIST, MY_WORKSHOPS } from "../../../graphql/query";
import pagination from "../../../components/pagination/pagination.vue";
import search from "../../../components/filters/search.vue";
import sortingText from "../../../components/filters/sorting.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Order",
  components: { pagination, search, sortingText },
  props: {
    titleText: {
      type: String,
      default: "Orders",
    },
    filterIsAssignedToMe: {
      type: Boolean,
      default: null,
    },
    filterIsQuoted: {
      type: Boolean,
      default: null,
    },
    filterIsFullyQuoted: {
      type: Boolean,
      default: null,
    },
    filterHasApprovedQuotation: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      showFilters: false,
      orderList: [],
      checkedIsQuoted: null,
      checkedQuoteRequired: null,
      isQuoted: null,
      isQuoteRequired: null,
      checkedIsFullyQuoted: null,
      isFullyQuoted: null,
      checkedIsAssignedToMe: null,
      isAssignedToMe: null,

      workshop: null,
      searchWorkshop: null,
      workshopsArray: [],

      hasApprovedQuotation: null,
      hasApprovedQuotationData: null,

      statusArray: ["Select Status", "SCHEDULED", "CANCELLED", "FULFILLED"],
      quotedArray: ["Select", "True", "False"],
      sortingData: [
        { id: "SCHEDULED_AT - ASC", name: "Scheduled At - Asc." },
        { id: "SCHEDULED_AT - DESC", name: "Scheduled At - Desc." },
        { id: "WORKSHOP_QUOTED_AT - ASC", name: "Quoted At - Asc." },
        { id: "WORKSHOP_QUOTED_AT - DESC", name: "Quoted At - Desc." },
        { id: "APPROVED_QUOTATION_AT - ASC", name: "Approved Quotation At - Asc." },
        { id: "APPROVED_QUOTATION_AT - DESC", name: "Approved Quotation At - Desc." },
      ],
      sorting: { id: "SCHEDULED_AT - DESC", name: "Scheduled At - Desc." },
      // sorting: 'SCHEDULED_AT - DESC',
      totalRows: 0,
      currentPage: 1,
      perPage: 20,
      after: null,
      before: null,
      hasNextPage: true,
      hasPreviousPage: false,
      last: null,
      first: null,
      direction: "",
      field: "",

      search: null,
      status: null,
      statusData: null,
      gte: null,
      lte: null,

      resetSvg: require("../../../assets/images/svg/reset.svg"),

      borderColor: false,
      countFilter: 0,

      gte_quoted: null,
      lte_quoted: null,
      orderFilters: {
        sort_by: "SCHEDULED_AT - DESC",
        status: null,
        filter_date_lte: null,
        filter_date_gte: null,
        filter_date_lte_quoted: null,
        filter_date_gte_quoted: null,
        isQuoted: null,
        isQuoteRequired: null,
        workshop: null,
        hasApprovedQuotation: null,
        userDetails:null,
        isFullyQuoted: null,
        isAssignedToMe: null,
        // orderSearch:null
      },
      customerSearch: null,
    };
  },
  computed: {
    ...mapGetters({
      stateOrderFilters: "orderFilters",
      stateSearch: "customerSearch",
    }),
  },
  watch: {
    orderFilters: function () {
      this.changeOrderFilters(this.orderFilters);
    },
    titleText: function () {
      this.after = null;
      this.before = null;
      this.first = this.perPage;
      this.last = null;
      this.currentPage = 1;
      this.changeOrderFilters(this.orderFilters);
      if (this.filterHasApprovedQuotation) {
        this.direction = "DESC";
        this.field = "APPROVED_QUOTATION_AT";
        this.sorting.id = "APPROVED_QUOTATION_AT - DESC";
        this.sorting.name = "Approved Quotation At - Desc." ;
      } else {
        this.direction = "DESC";
        this.field = "SCHEDULED_AT";
        this.sorting.id = "SCHEDULED_AT - DESC";
        this.sorting.name = "Scheduled At - Desc." ;
      }
      this.getOrderList();
    },
  },
  mounted() {
    if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem("reloaded");
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem("reloaded", "1");
      localStorage.removeItem("customer-phone");
    }

    this.userDetails = JSON.parse(localStorage.getItem("user-details"));

    if(!this.userDetails.canCreateOrders){
      document.getElementById('add-order').style.display = "none";
    }
    else{
      document.getElementById('add-order').style.display = "block";
    }

  },

  async created() {
    (this.direction = "DESC"), (this.field = "SCHEDULED_AT");
    this.countFilter = 0;

    this.orderFilters = this.stateOrderFilters;
    this.changeOrderFilters(this.stateOrderFilters);
    var value = JSON.parse(localStorage.getItem("vuex"));
    value = value.orderFilters;
    value.sort_by = this.field + " - " + this.direction;

    if (localStorage.getItem("vuex") !== null) {
      this.first = this.perPage;
      this.getOrderList();
    } else {
      this.countFilter = 0;
      this.first = this.perPage;
      this.getOrderList();
    }
  },

  methods: {
    ...mapActions({
      orderFilterChange: "orderFilterAction",
    }),
    refreshClick() {
      this.first = this.perPage;
      this.last = null;
      this.before = null;
      this.after = null;
      this.currentPage = 1;
      this.getOrderList();
    },

    handleWorkshop() {
      // this.$forceUpdate();
      this.$apollo
        .query({
          query: MY_WORKSHOPS,
          variables: {
            first: 10,
            search: this.searchWorkshop,
          },
        })
        .then((data) => {
          this.workshopsArray = data.data.myWorkshops;
        });
    },
    getCustomWorkshop(opt) {
      return opt.name;
    },
    handleSearchWorkshop(event) {
      if (event) {
        this.searchWorkshop = event;
        this.handleWorkshop();
      }
    },
    onChangeWorkshop(event) {
      this.workshop = event;
      this.orderFilters.workshop = event;
      this.after = null;
      this.before = null;
      this.last = null;
      this.first = this.perPage;
      this.currentPage = 1;
      this.changeOrderFilters(this.orderFilters);
      this.getOrderList();
    },
    removeWorkshop(selectedOption) {
      if (selectedOption) {
        this.workshop = null;
        this.before = null;
        this.after = null;
        this.last = null;
        this.first = this.perPage;
        this.currentPage = 1;
        this.orderFilters.workshop = null;
        this.changeOrderFilters(this.orderFilters);
        this.getOrderList();
      }
    },
    onChangeIsFullyQuoted(event) {
      if (event === "Select") {
        this.isFullyQuoted = null;
        this.orderFilters.isFullyQuoted = null;
      } else {
        this.isFullyQuoted = event;
        this.orderFilters.isFullyQuoted = event;
      }
      this.after = null;
      this.before = null;
      this.first = this.perPage;
      this.last = null;
      this.currentPage = 1;
      this.changeOrderFilters(this.orderFilters);
      this.getOrderList();
    },
    removeIsFullyQuoted(selectedOption) {
      if (selectedOption) {
        this.isFullyQuoted = null;
        this.orderFilters.isFullyQuoted = null;
        this.before = null;
        this.after = null;
        this.last = null;
        this.first = this.perPage;
        this.currentPage = 1;
        this.changeOrderFilters(this.orderFilters);
        this.getOrderList();
      }
    },

    async getOrderList() {
      // document.getElementById('order-list').style.display = 'none';
      // document.getElementById('page-bottom').style.display = 'none';
      // document.getElementById('load-spinner').style.display = 'flex';
      await this.$apollo
        .query({
          query: GET_ORDERS_LIST,
          variables: {
            direction: this.direction,
            field: this.field,
            first: this.first,
            after: this.after,
            before: this.before,
            last: this.last,
            status: this.status,
            gte: this.gte ? this.gte : null,
            lte: this.lte ? this.lte : null,
            isQuoted:
              this.filterIsQuoted !== null
              ? this.filterIsQuoted
              : (
                this.isQuoted == null
                ? null
                : this.isQuoted == "True"
                ? true
                : false),
            isQuoteRequired:
              this.isQuoteRequired == null
                ? null
                : this.isQuoteRequired == "True"
                ? true
                : false,
            hasApprovedQuotation:
              this.filterHasApprovedQuotation !== null
                ? this.filterHasApprovedQuotation
                : (
                  this.hasApprovedQuotation == null
                    ? null
                    : this.hasApprovedQuotation == "True"
                    ? true
                    : false),
            isFullyQuoted:
              this.filterIsFullyQuoted !== null
                ? this.filterIsFullyQuoted
                : (
                  this.isFullyQuoted == null
                  ? null
                  : this.isFullyQuoted == "True"
                  ? true
                  : false),
            search: this.search ? this.search.replace(/\s/g, "") : null,
            gte_quoted: this.gte_quoted ? this.gte_quoted : null,
            lte_quoted: this.lte_quoted ? this.lte_quoted : null,
            workshop: this.workshop ? this.workshop.id : null,
            isAssignedToMe: this.filterIsAssignedToMe ? true : this.isAssignedToMe,
          },
        })
        .then((data) => {
          document.getElementById("load-spinner").style.display = "none";
          document.getElementById("order-list").style.display = "block";
          document.getElementById("page-bottom").style.display = "flex";
          this.orderList = data.data.workshopOrders.edges;
          this.totalRows = data.data.workshopOrders.totalCount;
          this.after = data.data.workshopOrders.pageInfo.endCursor;
          this.before = data.data.workshopOrders.pageInfo.startCursor;
          this.hasNextPage = data.data.workshopOrders.pageInfo.hasNextPage;
          this.hasPreviousPage =
            data.data.workshopOrders.pageInfo.hasPreviousPage;
        });
    },
    filterClick() {
      this.countFilter = document.querySelectorAll(".borderColorFilter").length;
    },
    changeOrderFilters(value) {
      this.countFilter = 0;
      this.orderFilterChange({ orderFilters: value });

      if (value) {
        if (
          value.filter_date_gte !== null ||
          value.filter_date_lte !== null ||
          value.status !== null ||
          value.filter_date_gte_quoted !== null ||
          value.filter_date_lte_quoted !== null ||
          value.isQuoted != null ||
          value.isQuoteRequired != null ||
          value.workshop != null ||
          value.isFullyQuoted != null ||
          value.hasApprovedQuotation != null
        ) {
          this.showFilters = true;
          if (value.status) {
            this.statusData = value.status;
            this.status = value.status;
            this.countFilter += 1;
          } else {
            this.statusData = "";
            this.status = null;
          }

          if(value.isFullyQuoted === 'True'){
            this.checkedIsFullyQuoted = 'True';
            this.isFullyQuoted = 'True';
            this.countFilter += 1;
          }
          else if(value.isFullyQuoted === 'False'){
            this.checkedIsFullyQuoted = 'False';
            this.isFullyQuoted = 'False';
            this.countFilter += 1;
          }
          else{
            this.checkedIsFullyQuoted = null;
            this.isFullyQuoted = null;
          }

          if (value.filter_date_lte) {
            this.lte = value.filter_date_lte;
            this.countFilter += 1;
          }

          if (value.filter_date_gte) {
            this.gte = value.filter_date_gte;
            this.countFilter += 1;
          }

          var res = value.sort_by.split(" - ");

          this.field = res[0];
          this.direction = res[1];
          if (value.sort_by) {
            var data = value.sort_by
              .replace("_", " ")
              .replace(/\w\S*/g, function (txt) {
                return (
                  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                );
              });
          }

          this.sorting.id = value.sort_by;
          this.sorting.name = data;

          if (value.filter_date_lte_quoted) {
            this.lte_quoted = value.filter_date_lte_quoted;
            this.countFilter += 1;
          }

          if (value.filter_date_gte_quoted) {
            this.gte_quoted = value.filter_date_gte_quoted;
            this.countFilter += 1;
          }

          if (value.isQuoted === true) {
            this.checkedIsQuoted = "True";
            this.isQuoted = true;
            this.countFilter += 1;
          } else if (value.isQuoted === false) {
            this.checkedIsQuoted = "False";
            this.isQuoted = false;
            this.countFilter += 1;
          } else {
            this.checkedIsQuoted = "";
            this.isQuoted = null;
          }

          if (value.hasApprovedQuotation === "True") {
            this.hasApprovedQuotationData = "True";
            this.hasApprovedQuotation = "True";
            this.countFilter += 1;
          } else if (value.hasApprovedQuotation === "False") {
            this.hasApprovedQuotationData = "False";
            this.hasApprovedQuotation = "False";
            this.countFilter += 1;
          } else {
            this.hasApprovedQuotation = null;
            this.hasApprovedQuotationData = null;
          }

          if (value.isQuoteRequired === true) {
            this.checkedQuoteRequired = "True";
            this.isQuoteRequired = true;
            this.countFilter += 1;
          } else if (value.isQuoted === false) {
            this.checkedQuoteRequired = "False";
            this.isQuoteRequired = false;
            this.countFilter += 1;
          } else {
            this.checkedQuoteRequired = "";
            this.isQuoteRequired = null;
          }

          if (value.workshop) {
            this.workshop = value.workshop;
            this.countFilter += 1;
          }
          this.showFilters = false;
        } else {
          this.showFilters = false;
        }
      }
      if (this.filterHasApprovedQuotation) {
        this.direction = "DESC";
        this.field = "APPROVED_QUOTATION_AT";
        this.sorting.id = "APPROVED_QUOTATION_AT - DESC";
        this.sorting.name = "Approved Quotation At - Desc." ;
      }
    },

    onContext(ctx) {
      this.nextServiceDue = ctx.selectedYMD;
    },
    onChangehasApprovedQuotation(event) {
      if (event === "Select") {
        this.hasApprovedQuotation = null;
        this.orderFilters.hasApprovedQuotation = null;
      } else {
        this.hasApprovedQuotation = event;
        this.orderFilters.hasApprovedQuotation = event;
      }
      this.after = null;
      this.before = null;
      this.first = this.perPage;
      this.last = null;
      this.currentPage = 1;
      this.changeOrderFilters(this.orderFilters);
      this.getOrderList();
    },
    removehasApprovedQuotation(selectedOption) {
      if (selectedOption) {
        this.hasApprovedQuotation = null;
        this.orderFilters.hasApprovedQuotation = null;
        this.before = null;
        this.after = null;
        this.last = null;
        this.first = this.perPage;
        this.currentPage = 1;
        this.changeOrderFilters(this.orderFilters);
        this.getOrderList();
      }
    },

    onChangeIsQuoted(event) {
      if (event === "Select") {
        this.isQuoted = null;
      } else {
        this.isQuoted = event;
      }
      this.after = null;
      this.before = null;
      this.first = this.perPage;
      this.last = null;
      this.currentPage = 1;
      this.getOrderList();
    },
    removeIsQuoted(selectedOption) {
      if (selectedOption) {
        this.isQuoted = null;
        this.before = null;
        this.after = null;
        this.last = null;
        this.first = this.perPage;
        this.currentPage = 1;
        this.getOrderList();
      }
    },
    handleIsAssignedToMe() {
      if (this.checkedIsAssignedToMe === true || this.filterIsAssignedToMe === true) {
        this.isAssignedToMe = true;
        this.orderFilters.isAssignedToMe = true;
      } else {
        this.isAssignedToMe = false;
        this.orderFilters.isAssignedToMe = false;
      }
      this.after = null;
      this.before = null;
      this.first = this.perPage;
      this.last = null;
      this.currentPage = 1;
      this.changeOrderFilters(this.orderFilters);
      this.getOrderList();
    },
    onChangeIsQuoteRequired(event) {
      if (event === "Select") {
        this.isQuoteRequired = null;
      } else {
        this.isQuoteRequired = event;
      }
      this.after = null;
      this.before = null;
      this.first = this.perPage;
      this.last = null;
      this.currentPage = 1;
      this.getOrderList();
    },
    removeIsQuoteRequired(selectedOption) {
      if (selectedOption) {
        this.isQuoteRequired = null;
        this.before = null;
        this.after = null;
        this.last = null;
        this.first = this.perPage;
        this.currentPage = 1;
        this.getOrderList();
      }
    },
    handleSearchOrder(event) {
      this.search = event;
      this.after = null;
      this.before = null;
      this.last = null;
      this.first = this.perPage;
      this.currentPage = 1;
      this.getOrderList();
    },
    handlePageChange(event) {
      if (event > this.currentPage && this.hasNextPage) {
        this.currentPage = event;
        this.before = null;
        this.last = null;
        this.first = this.perPage;
        this.after;
        this.getOrderList();
      } else if (event < this.currentPage && this.hasPreviousPage) {
        this.currentPage = event;
        this.after = null;
        this.before;
        this.last = this.perPage;
        this.first = null;
        this.getOrderList();
      }
    },
    onChangeSorting(event) {
      if (event.id !== null) {
        var res = event.id.split("-");
        this.field = res[0].replaceAll(" ", "");
        this.direction = res[1].replaceAll(" ", "");
        this.before = null;
        this.after = null;
        this.currentPage = 1;
        this.first = this.perPage;
        this.last = null;
        this.orderFilters.sort_by = this.field + " - " + this.direction;
        this.changeOrderFilters(this.orderFilters);
        this.getOrderList();
      } else {
        (this.direction = "DESC"), (this.field = "SCHEDULED_AT");
        this.orderFilters.sort_by = this.field + " - " + this.direction;
        this.changeOrderFilters(this.orderFilters);
        this.before = null;
        this.after = null;
        this.first = this.perPage;
        this.last = null;
        this.currentPage = 1;
        this.getOrderList();
      }
    },
    onChangeStatus(event) {
      if (event === "Select Status") {
        this.status = null;
        this.orderFilters.status = null;
      } else {
        this.status = event;
        this.orderFilters.status = event;
      }

      this.after = null;
      this.before = null;
      this.first = this.perPage;
      this.last = null;
      this.currentPage = 1;
      this.changeOrderFilters(this.orderFilters);
      this.getOrderList();
    },
    onChangeFromDate(event) {
      this.gte = event;
      this.after = null;
      this.before = null;
      this.first = this.perPage;
      this.last = null;
      this.currentPage = 1;
      this.orderFilters.filter_date_gte = event;
      this.changeOrderFilters(this.orderFilters);
      this.getOrderList();
    },
    onChangeToDate(event) {
      this.lte = event;
      this.after = null;
      this.before = null;
      this.first = this.perPage;
      this.last = null;
      this.currentPage = 1;
      this.orderFilters.filter_date_lte = event;
      this.changeOrderFilters(this.orderFilters);
      this.getOrderList();
    },
    removeFromDate() {
      this.gte = null;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.first = this.perPage;
      this.last = null;
      this.orderFilters.filter_date_gte = null;
      this.changeOrderFilters(this.orderFilters);
      this.getOrderList();
    },
    removeToDate() {
      this.lte = null;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.first = this.perPage;
      this.last = null;
      this.orderFilters.filter_date_lte = null;
      this.changeOrderFilters(this.orderFilters);
      this.getOrderList();
    },

    resetFilters() {
      this.currentPage = 1;
      this.gte = null;
      this.lte = null;
      this.after = null;
      this.before = null;
      this.first = this.perPage;
      (this.last = null),
        (this.search = null),
        (this.lte_quoted = null),
        (this.gte_quoted = null),
        (this.countFilter = 0);
      this.isQuoted = null;
      this.isQuoteRequired = null;
      this.isFullyQuoted = null;
      this.checkedIsQuoted = null;
      this.checkedQuoteRequired = null;
      this.orderFilters.assignedTo = null;
      this.orderFilters.workshop = null;
      this.orderFilters.isQuoted = null;
      this.orderFilters.isQuoteRequired = null;
      this.orderFilters.filter_date_gte = null;
      this.orderFilters.filter_date_lte = null;
      this.orderFilters.filter_date_gte_quoted = null;
      this.orderFilters.filter_date_lte_quoted = null;
      this.orderFilters.status = null;
      this.orderFilters.hasApprovedQuotation = null;
      this.orderFilters.isFullyQuoted = null;
      (this.hasApprovedQuotation = null),
        (this.hasApprovedQuotationData = null),
        localStorage.removeItem("vuex");
      this.changeOrderFilters(this.orderFilters);
      this.getOrderList();
    },

    onChangeQuotedAtFromDate(event) {
      this.gte_quoted = event;
      this.after = null;
      this.before = null;
      this.first = this.perPage;
      this.last = null;
      this.currentPage = 1;
      this.orderFilters.filter_date_gte_quoted = event;
      this.changeOrderFilters(this.orderFilters);
      this.getOrderList();
    },
    onChangeQuotedAtToDate(event) {
      this.lte_quoted = event;
      this.after = null;
      this.before = null;
      this.first = this.perPage;
      this.last = null;
      this.currentPage = 1;
      this.orderFilters.filter_date_lte_quoted = event;
      this.changeOrderFilters(this.orderFilters);
      this.getOrderList();
    },
    removeQuotedAtFromDate() {
      this.gte_quoted = null;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.first = this.perPage;
      this.last = null;
      this.orderFilters.filter_date_gte_quoted = null;
      this.changeOrderFilters(this.orderFilters);
      this.getOrderList();
    },
    removeQuotedAtToDate() {
      this.lte_quoted = null;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.first = this.perPage;
      this.last = null;
      this.changeOrderFilters(this.orderFilters);
      this.getOrderList();
    },
    onChangeUpdatedAtFromDate(event) {
      this.gte_updated = event;
      this.after = null;
      this.before = null;
      this.first = this.perPage;
      this.last = null;
      this.currentPage = 1;
      this.orderFilters.filter_date_gte_updated = event;
      this.changeOrderFilters(this.orderFilters);
      this.getOrderList();
    },
    onChangeUpdatedAtToDate(event) {
      this.lte_updated = event;
      this.after = null;
      this.before = null;
      this.first = this.perPage;
      this.last = null;
      this.currentPage = 1;
      this.orderFilters.filter_date_lte_updated = event;
      this.changeOrderFilters(this.orderFilters);
      this.getOrderList();
    },
    removeUpdatedAtFromDate() {
      this.gte_updated = null;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.first = this.perPage;
      this.last = null;
      this.orderFilters.filter_date_gte_updated = null;
      this.changeOrderFilters(this.orderFilters);
      this.getOrderList();
    },
    removeUpdatedAtToDate() {
      this.lte_updated = null;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.first = this.perPage;
      this.last = null;
      this.orderFilters.filter_date_lte_updated = null;
      this.changeOrderFilters(this.orderFilters);
      this.getOrderList();
    },

    copyIdToClipboard(id) {
      var input_temp = document.createElement("textarea");
      input_temp.setAttribute("class", "text-area-clipboard");
      input_temp.innerHTML = id;
      document.body.appendChild(input_temp);
      input_temp.select();
      input_temp.setSelectionRange(0, 99999);
      document.execCommand("copy");

      var message = "Copied";
      this.$bvToast.toast(id + " " + `${message}`, {
        toaster: "b-toaster-top-center",
        variant: "success",
        solid: true,
        toastClass: "text-center toast-box toast-style",
      });
    },
    isLessThan48HourAgo(date) {
      // :point_down:️   hour  min  sec  milliseconds
      const twentyFourHrInMs = 48 * 60 * 60 * 1000;
      const twentyFourHoursAgo = Date.now() - twentyFourHrInMs;
      return date < twentyFourHoursAgo && date <= Date.now();
    },
  },
};
</script>


<style>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(50px);
  opacity: 0;
}

.rotate {
  transform: rotate(180deg);
}

.table-font {
  font-size: 0.9rem;
}

.filter-label-heading {
  font-weight: 600;
  /* font-size: 0.9rem; */
  font-size: 12px;
  text-transform: capitalize !important;
}
table.table-order {
  text-align: center;
}
.filter-btn {
  font-size: 1.1rem !important;
}

.multOptions .multiselect__tags {
  /* height: 72px; */
  height: 55px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.multiselect__tag {
  position: relative;
  display: inline-block;
  /* display: inline-table; */
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.singleOptions .multiselect__tags {
  min-height: 47px;
}

.customMultiSelect-div {
  max-width: 22.2%;
  flex: 0 0 22.2%;
}
.click-area-extend {
  padding: 0.25rem 1rem 0.25rem 1rem;
  cursor: pointer;
}
.toast-style {
  font-size: 1.2rem;
  padding: 1rem;
}
.customer-phone:hover {
  color: blue;
  cursor: pointer;
}
.text-area-clipboard {
  border: none;
  color: white;
  height: 0;
  width: 0;
}
.transition-row {
  transition: transform 0.1s;
}
.transition-row:hover {
  background-color: rgb(245, 245, 245);
  /* transform: scale(0.99); */
}
.table-zoom {
  zoom: 90%;
}
.table th,
.table td {
  padding: 12px 12px;
}

.b-toast-success.b-toast-solid .toast {
  padding: 10px;
  width: 200px;
  margin: 0 auto;
}

.white-space {
  overflow: auto;
  white-space: nowrap;
}
.card-body-2 {
  padding: 10px 20px 20px 20px;
}
.singleOptions .multiselect__tags {
  min-height: 10px !important;
}
.multiselect {
  min-height: 10px !important;
}
#order-container .singleOptions .multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  padding-left: 5px;
  min-height: 12px !important;
  line-height: 12px !important;
  margin-bottom: 7px;
}

#order-container .borderColorFilter {
  /* background-color: gray; */
  border: 2px solid #9a18ffd1 !important;
  border-radius: 5px;
}

.singleOptions .form-control-sm {
  line-height: 24px;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 13px !important;
}
.multiselect__input,
.multiselect__single {
  min-height: 12px !important;
  line-height: 14px !important;
}
.multiselect__select {
  height: 31px !important;
}
.filter-cross-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0 0.75rem 0 0.75em;
  border-radius: 0.25rem;
}

.b-form-btn-label-control.form-control > .form-control.form-control-sm {
  overflow: auto !important;
  white-space: nowrap !important;
}
.reset-btn-padding {
  padding: 0.4rem;
}

.reset-btn-padding-onlist {
  padding: 0.4rem;
}

.assigned-to-custom {
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 40%;
  right: 20%;
  z-index: 1;
  transform: translate(0%, -50%);
}

.notify .badge {
  background: radial-gradient(5px -9px, circle, white 8%, #ed2324 26px);
  background: -moz-radial-gradient(5px -9px, circle, white 8%, #ed2324 26px);
  background: -ms-radial-gradient(5px -9px, circle, white 8%, #ed2324 26px);
  background: -o-radial-gradient(5px -9px, circle, white 8%, #ed2324 26px);
  background: -webkit-radial-gradient(5px -9px, circle, white 8%, #ed2324 26px);
  background-color: #ed2324;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 5% 5%;
  float: right;
  display: block;
  position: relative;
  top: -16px;
  left: 12px;
}

.message-count {
  position: relative;
  display: block;
  font: bold 14px/13px Helvetica, Verdana, Tahoma;
  text-align: center;
  margin: 0;
  top: 2px;
}
.red-highlight {
  background: #ff00003b;
}
.red-highlight:hover {
  background: #ff00004a;
}
.green-highlight {
  background: #4cd74c8f;
}
.green-highlight:hover {
  background: #4cd74cbd;
}
#order-list {
  display: none;
}
#page-bottom {
  display: none;
}

#load-spinner {
  justify-content: center;
  display: flex;
}

.display-search .search-input {
  margin-top: 45px;
}

@media only screen and (max-width: 768px) {
  .table th,
  .table td {
    padding: 5px 5px;
  }
  .customMultiSelect-div {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 768px) {
  .col-half-offset {
    margin-left: 4.166666667%;
  }
}

/* @media only screen and (max-width: 470px) {
  .mobile-align-customer {
    justify-content: flex-start;
    display: flex;
    align-items: center;
  }
} */

@media only screen and (max-width: 417px) {
  .sorting-div {
    margin-bottom: 1rem;
  }
  .filter-div {
    margin-left: 0rem !important;
  }
}

/* @media only screen and (max-width:407px){
  .filters-div{
    margin-top:8px;
  }
} */
</style>


